.Reasons {
    padding: 0 6rem;
    display: flex;
    gap: 2rem;
}

.left-r {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    grid-auto-rows: 1fr;
}

.left-r>img {
    object-fit: cover;
}

.left-r>:nth-child(1) {
    width: 16rem;
    grid-row: 1/3;
    height: 36rem;
}

.left-r>:nth-child(2) {
    width: 37rem;
    height: 19rem;
    grid-row: 1;
    grid-column: 2/4;
}

.left-r>:nth-child(3) {
    width: 21rem;
    height: 15rem;
    grid-row: 2;
    grid-column: 2/3;
}

.left-r>:nth-child(4) {
    width: 14rem;
    grid-row: 2;
    grid-column: 3/4;
}
.right-r {
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-r>span {
    font-weight: bold;
    font-size: 1.3rem;
    color: var(--orange);
}

.right-r>div{
    color: white;
    font-size: 4rem;
    font-weight: bold;
}

.details-r {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r>div {
    display: flex;
    font-size: 1.3rem;
    gap: 1.3rem;
}

.details-r>div>img {
    width: 2.5rem;
    height: 2.5rem;
}

#our-partner {
    color: gray;
    font-weight: normal;
}

.partners {
    display: flex;
    gap: 1rem;
}

.partners>img {
    width: 2.8rem;
}


@media screen and (max-width: 1440px) {

    .Reasons {
        padding:0 2rem;
    }

    .left-r {
        grid-auto-rows: auto;
        overflow: hidden;
        gap: 0.5rem;
    }

    .left-r > :nth-child(1) {
        width: 12rem;
        height: 27rem;
    }

    .left-r > :nth-child(2) {
        width: 24rem;
        height: 15rem;
    }

    .left-r > :nth-child(3) {
        width: 12rem;
        height: 11rem;
    }

    .left-r > :nth-child(4) {
        width: 11.5rem;
        height: 11rem;
    }
}


@media screen and (max-width: 768px) {
    .Reasons {
        margin-top: 3rem;
        padding:0 2rem;
        flex-direction: column;
    }

    .left-r {
        grid-auto-rows: auto;
        overflow: hidden;
        gap: 0.5rem;
    }

    .left-r > :nth-child(1) {
        width: 7rem;
        height: 17rem;
    }

    .left-r > :nth-child(2) {
        width: 11rem;
        height: 9.5rem;
    }

    .left-r > :nth-child(3) {
        width: 8.5rem;
        height: 7rem;
    }

    .left-r > :nth-child(4) {
        display: none;
    }

    .right-r>div {
        font-size: 3rem;
    }

    .details-r>div {
        font-size: medium;
    }

    .details-r>div>img {
        width: 1.5rem;
        height: 1.5rem;
    }

    .partners>img {
        width: 2rem;
        margin-bottom: 6rem;
    }
    
    
}